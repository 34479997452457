import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FeedbackSuccessComponent } from './feedback-success.component';



@NgModule({
  declarations: [FeedbackSuccessComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  exports: [FeedbackSuccessComponent],
})
export class FeedbackSuccessModule {}
